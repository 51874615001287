<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-btn
      color="#aaa"
      class="ml-7 my-back-btn-style"
      text
      x-large
      dark
      @click="back()"
    >
      <v-icon
        left
        dark
      >
        mdi-arrow-left
      </v-icon>
      Back
    </v-btn>
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-account-group
      </v-icon> Project App Users
    </h2>
    <div v-if="showMoreDetails">
      <p class="project-header">
        Project Name:- <font
          class="project-name"
        >
          {{ projectCompanyAppUsers[0].project_company.project.name }}
        </font>
      </p>
      <p class="company-header">
        Company Name:- <font
          class="company-name"
        >
          {{ projectCompanyAppUsers[0].project_company.company.name }}
        </font>
      </p>
    </div>
    <v-card
      color="#fff"
      class="project-list-card"
    >
      <v-card-title class="filter-card">
        <v-col
          cols="12"
          lg="4"
          md="5"
          sm="12"
        >
          <v-text-field
            v-model="search"
            label="Search"
            append-icon="mdi-magnify"
            outlined
            dense
          />
        </v-col>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="projectCompanyAppUsers"
        :search="search"
        class="elevation-1 table-projects"
        disable-sort
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.app_user.first_name }} {{ item.app_user.last_name }}</td>
            <td>{{ item.app_user.phone_number }}  </td>
            <td>
              <span v-if="item.app_user.worker_category !== null">{{ item.app_user.worker_category.name }}</span>
              <span v-else>N/A</span>
            </td>
            <td>
              <span
                v-if="item.types !== null"
                class="type-style"
              > {{ item.app_user.types | arrangePretty }} </span>
              <span v-else>
                N/A
              </span>
            </td>
            <td>{{ item.app_user.work_hours }} Hrs</td>
            <td>
              <font
                class="status-style"
                :color="getStatusColor(item.permit)"
              >
                {{ item.permit | projectAppUserStatus }}
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="editProjectAppUserStatus(item.permit, item.id)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <edit-project-app-user-status
      v-if="showEditDialog"
      :app-user-id="appUserId"
      :app-user-status="appUserStatus"
      @closed="showEditDialog = false"
      @success="getProjectCompanyAppUsers"
    />
  </v-container>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';
  import EditProjectAppUserStatus from './EditProjectAppUserStatus.vue';

  export default {
    name: 'ProjectCompanyAppUsers',
    components: {
      'centre-spinner': spinner,
      'edit-project-app-user-status': EditProjectAppUserStatus,
    },
    filters: {
      arrangePretty (val) {
  if (Array.isArray(val)) {
    return val.map(type => type === 'staff' ? 'Site Manager' : type).join(', ');
  }
  return val;
},
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      projectAppUserStatus (status) {
        if (status === false) {
          return 'Blocked';
        } else {
          return 'Permitted*';
        }
      },
    },
    data () {
      return {
        search: '',
        showForm: false,
        loading: false,
        headers: [
          { text: 'Name', align: 'start', value: 'app_user.first_name' },
          { text: 'Phone', value: 'mobile_number' },
          { text: 'Category', value: 'worker_category' },
          { text: 'Types', value: 'type' },
          { text: 'Work Hours', value: 'work_hours' },
          { text: 'Status', value: 'permit' },
          { text: 'Edit', value: 'edit' },
        ],
        appUserStatus: false,
        showAppUserPrjCheckIns: false,
        appUserDetails: {},
        showEditDialog: false,
      };
    },
    computed: {
      projectCompanyAppUsers () {
        return this.$store.getters['projects/getProjectCompanyAppUsers'];
      },
      showMoreDetails () {
       if (this.projectCompanyAppUsers.length > 0) {
        return true;
       }
       return false;
      },
    },
    async mounted () {
      await this.getProjectCompanyAppUsers();
    },
    methods: {
      async getProjectCompanyAppUsers () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchProjectCompanyAppUsers', {
          projectCompanyId: this.$route.params.companyId,
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      back () {
        this.$store.dispatch('projects/storeProjectSecondNavDrawerTab', {
          toTab: 2,
        });
        const projectId = this.$route.params.projectId;
        this.$router.push(`/projects/${projectId}/details`);
      },
      getStatusColor (status) {
        if (status === false) {
          return '#C62828';
        } else {
          return '#2E7D32';
        }
      },
      editProjectAppUserStatus (permit, id) {
      this.appUserId = id;
      this.appUserStatus = permit;
      this.showEditDialog = true;
    },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  margin-top: 5px;
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.type-style {
  text-transform: capitalize;
}
.all-users-btn {
  margin-bottom: 25px;
}
.wrk-hr-btn {
  margin-right: 15px;
}
.my-back-btn-style {
  margin-left: -10px !important;
}
@media (max-width: 960px) {
  .my-back-btn-style {
    margin-bottom: 0px;
    margin-left: 0px !important;
  }
}
.project-header {
  margin-top: 10px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
  color: #37474F;
  font-size: 18px;
}
.project-name {
  color: #FF3700;
}
.company-header {
  margin-top: -10px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
  color: #37474F;
  font-size: 18px;
}
.company-name {
  color: #546E7A;
}

.status-style {
  font-weight: bold;
}
.table-projects {
    border-radius: 0px !important;
    background: #fff;
    box-shadow: none !important;
    border: 1px solid #ccc;
    border-top: none;
  }
  .project-list-card {
    box-shadow: none !important;
  }
  .table-projects tbody tr:nth-of-type(even) {
    background: #fff !important;
  }
  .table-projects tbody tr:nth-of-type(odd):hover {
    background: #fff !important;
  }
  .action-btn {
    background: #fff !important;
  }
  .filter-card {
    border: 1px solid #ccc !important;
    padding: 15px;
  }
  .table-top-border{
    border: 1px solid #ccc;
    border-bottom: none;
    background: #edf6f9;
  }
  .info-text{
    margin-bottom: 0px !important;
  }
</style>
