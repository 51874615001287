<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
  >
    <v-card
      outlined
      color="#fff"
    >
      <br>
      <h2 class="title-style">
        <v-icon
          class="icon-style"
          left
        >
          mdi-account-edit
        </v-icon>
        Update Status
      </h2>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container fluid>
          <v-card-text>
            <p class="my-p-style">
              Update App User Entry Status <br><br>
              <span class="my-p-style-sub">NB:- Change this app user's entry status from this page to determine whether or not they are allowed to enter the project site.</span>
            </p>
          </v-card-text>
          <v-row
            class="fill-height ml-2 mr-2"
          >
            <v-col
              cols="12"
              md="12"
            >
              <v-select
                v-model="editProjectAppUserStatus"
                label="Entry Status*"
                :items="statusList"
                item-text="name"
                item-id="value"
                single-line
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              @click="updateProjectAppUserStatus"
            >
              Update
            </v-btn>
            <v-btn
              class="mt-2"
              color="#aaa"
              @click="showModal = false"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import Constants from 'src/constants';
import spinner from 'src/views/dashboard/component/SpinnerCentre';

export default {
  name: 'EditProjectAppUserStatus',
  components: {
    'centre-spinner': spinner,
  },
  props: {
    appUserId: {
      type: String,
      required: true,
    },
    appUserStatus: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      showModal: true,
      isFormValid: false,
      loading: false,
      statusList: [
        { name: 'Permit', value: true },
        { name: 'Block', value: false },
      ],
      editProjectAppUserStatus: false,
    };
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
  },
  mounted () {
    this.loadOnMount();
  },
  methods: {
    updateProjectAppUserStatus () {
      if (this.$refs.form.validate() === false) {
        this.$store.dispatch('alert/onAlert', {
          message: 'Please fill the required fields before proceeding.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
        return;
      }
      this.loading = true;
      this.$store.dispatch('projects/updateProjectAppUserStatus', {
        appUserId: this.appUserId,
        status: {
          permit: this.editProjectAppUserStatus,
        },
      }).then(response => {
        this.$store.dispatch('alert/onAlert', {
          message: 'App user project entry updated successfully.',
          type: Constants.ALERT_TYPE_SUCCESS,
        });
        this.$emit('success');
        this.loading = false;
        this.showModal = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    loadOnMount () {
      this.editProjectAppUserStatus = this.appUserStatus;
    },

  },
};
</script>

<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-p-style {
  font-size: 18px;
  font-weight: bold;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.my-p-style-sub {
  font-size: 16px;
  font-weight: bold;
  color: #ccc;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
</style>
